<template>
  <div
    class="subheader py-3 py-lg-8 subheader-transparent"
    v-bind:class="subheaderClasses"
    id="kt_subheader"
  >
    <div
      class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <div class="d-flex align-items-baseline mr-5">
        <!--begin::Page Title-->
        <div class="subheader-title badge badge-primary font-weight-bold my-2 mr-3">
          <template v-if="currentUser.akses">
            <span v-if="currentUser.akses.tipe_name || currentUser.akses.perusahaan_name">
              {{ currentUser.akses.tipe_name || "" }} | {{ currentUser.akses.perusahaan_name || "" }}
            </span>
          </template>
        </div>
        <!--end::Page Title-->
        <!--begin::Breadcrumb-->
        <ul
          class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold my-2 p-0"
        >
          <template v-for="(breadcrumb, i) in breadcrumbs">
            <li :key="`${i}-${breadcrumb.id}`" class="breadcrumb-item">
              <router-link
                :key="`${i}-${breadcrumb.id}`"
                v-if="breadcrumb.route"
                :to="breadcrumb.route"
                class="text-muted"
              >
                {{ breadcrumb.title }}
              </router-link>
              <span
                class="text-muted"
                :key="`${i}-${breadcrumb.id}`"
                v-if="!breadcrumb.route"
              >
                {{ breadcrumb.title }}
              </span>
            </li>
          </template>
        </ul>
        <!--end::Breadcrumb-->
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTSubheader",
  props: {
    breadcrumbs: Array,
    title: String
  },
  computed: {
    ...mapGetters(["layoutConfig", "currentUser"]),

    /**
     * Check if subheader width is fluid
     */
    widthFluid() {
      return this.layoutConfig("subheader.width") === "fluid";
    },

    subheaderClasses() {
      const classes = [];
      const style = this.layoutConfig("subheader.style");
      if (style) {
        classes.push(style);

        if (style === "solid") {
          classes.push("bg-white");
        }

        if (this.layoutConfig("subheader.fixed")) {
          classes.push("border-top");
        }

        if (this.layoutConfig("subheader.clear")) {
          classes.push("mb-0");
        }
      }
      return classes.join(" ");
    }
  }
};
</script>
